import * as React from "react"

import Layout from "../components/sections/layout"
import Seo from "../components/sections/seo"

// import templates
import BasicLayout from "../templates/basic-layout"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <BasicLayout
      pageName={"404: Not found"}
      description={`
<p>Im sorry but teh page you are looking doesn't exist</p>
<button><a href="/">Return to homepage</a></button>
`}
      rightImage={""}
      images={[]}
    />
  </Layout>
)

export default NotFoundPage
